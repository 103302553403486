class ServiceOption {
  constructor () {
    this.id = null
    this.value = null
    this.sort_order = null
    this.parent_id = null
    this.category_id = null
  }
}

export default ServiceOption
