<template>
  <div>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <div class="navAutoHeight">
                <div
                  v-for="(item,index) in serviceOptions"
                  :key="index"
                >
                  <v-list-item mandatory>
                    <v-list-item-content>
                      <v-list-item-title class="title">
                        {{item.title}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <div v-if="item.children">
                    <v-list
                      dense
                      nav
                    >
                      <v-list-item
                        v-for="(childrenItem, childrenIndex) in item.children"
                        :key="childrenIndex"
                        link
                        @click="categoryChosen(item.name, childrenItem.name, childrenItem.title, childrenItem.isChangeable)"
                      >
                        <v-list-item-content>
                          <v-list-item-title :class="childrenItem.isChangeable ? '' : 'non-editable'">
                            {{childrenItem.title}}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="8">
              <h1 class="mb-8">Сервисные опции</h1>
              <div
                v-if="!this.path"
                class="d-flex"
              >
                <v-alert
                  prominent
                  text
                  type="error"
                  color="red"
                  icon="mdi-arrow-left"
                >Выберите необходимую опцию
                </v-alert>
              </div>
              <!--Конец блока-->
              <div>
                <h3 class="mb-3">{{title}} {{isChangeable ? '' : ' (не редактируется!)'}}</h3>
                <div
                  class="mb-3"
                  v-for="(item, i) in serviceValues"
                  :key="i+'-'+serviceValues.length"
                >
                  <OptionItem
                    :value="item"
                    :path="path"
                    :loading="isLoading"
                    :isChangeable="isChangeable"
                    @delete="handleRemove(i, item.id)"
                  />
                </div>
                <v-btn
                  class="primary"
                  @click="addServiceOption()"
                  :disabled="!isChangeable"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="text-right saveBlock">
          <v-btn
            class="mr-2"
            small
            dark
            color="light-green"
            @click="saveDetail"
            :loading="isLoading"
          >
            <v-icon class="pr-3">mdi-content-save</v-icon>
            Сохранить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import OptionItem from '@/components/views/admin/service-options/OptionItem'
import ServiceOption from '@/components/structures/serviceOption'

export default {
  name: 'ServiceOptions',
  components: { OptionItem },
  data () {
    return {
      path: 'advert.source',
      title: 'Источник объявления',
      isLoading: false,
      isChangeable: true // используем как маркер неизменяемых разделов справочных данных
    }
  },
  computed: {
    serviceOptions () {
      return this.$store.getters['serviceData/optionsList']()
    },
    serviceValues: {
      get () {
        return this.$store.getters['serviceData/optionsList'](this.path, false)
      }
    }
  },
  methods: {
    addServiceOption () {
      this.serviceValues.push(new ServiceOption())
      this.$store.commit('serviceData/setOptions', { categoryChain: this.path, data: this.serviceValues })
    },
    async handleRemove (index, id) {
      let result = false
      if (!this.isLoading) {
        this.isLoading = true
        result = await this.$store.dispatch('serviceData/delete', { id })
        if (result) {
          this.serviceValues.splice(index, 1)
          this.$store.commit('serviceData/setOptions', { categoryChain: this.path, data: this.serviceValues })
        }
        this.isLoading = false
      }
      return result
    },
    async saveDetail () {
      if (!this.isLoading) {
        this.isLoading = true
        const response = await this.$store.dispatch('serviceData/save', { data: this.serviceValues })
        if (response) {
          this.$store.commit('serviceData/setOptions', { categoryChain: this.path, data: response })
        }
        this.isLoading = false
      }
      return true
    },
    categoryChosen (itemName, childrenName, title, isChangeable) {
      this.path = itemName + '.' + childrenName
      this.title = title
      this.isChangeable = !!isChangeable
    }
  }
}
</script>

<style scoped>
  .navAutoHeight {
    height: 80vh;
    overflow-y: scroll;
  }
  .non-editable {
    color: red;
  }
</style>
