<template>
  <v-row
    class="align-center"
  >
    <v-col cols="10">
      <v-row>
        <v-text-field
          v-model="value.value"
          label="Название"
          class="flex-grow-1"
          :disabled="!isChangeable"
        ></v-text-field>
        <v-text-field
          v-model="value.sort_order"
          label="Порядок сортировки"
          type="number"
          class="flex-grow-0 ml-1"
          :disabled="!isChangeable"
        ></v-text-field>
        <v-select
          v-if="path === 'building.subdistrict'"
          v-model="value.parent_id"
          label="Район"
          :items="districtCustomItems"
          item-value="id"
          item-text="value"
          :disabled="!isChangeable"
        ></v-select>
      </v-row>
    </v-col>
    <v-col cols="2">
      <v-btn
        icon
        @click="$emit('delete')"
        :loading="loading"
        :disabled="!isChangeable"
      >
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'

export default {
  mixins: [serviceDataComputeds],
  name: 'OptionItem',
  props: {
    value: Object,
    path: String,
    loading: Boolean,
    isChangeable: Boolean
  }
}
</script>

<style scoped>

</style>
